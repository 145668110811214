<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <CCol class="row">
              <CCol col="11">
                {{ $lang.cms.crud.edit }}
              </CCol>
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CAlert
                v-show="err_msg"
                color="danger"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onSubmit">
              <div class="form-group">
                <label class="form__label">{{ $lang.cms.form.page }}</label>
                <v-select :options="cmsMasterPages" v-model="cms.page">
                </v-select>
                <small
                    class="error"
                    v-if="$v.cms.page.$error && !$v.cms.page.required"
                >{{ $lang.cms.validation.required.page }}</small
                >
              </div>
              <div class="form-group">
                <label class="form__label">{{ $lang.cms.form.type }}
                  <required_span/>
                </label>
                <v-select id="type" :options="typeOptions" v-model="cms.type"></v-select>
                <small class="error" v-if="$v.cms.$error && !$v.cms.type.required">{{
                    $lang.cms.validation.required.type
                  }}</small>
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.cms.title.$error }"
              >
                <label class="form__label"
                >{{ $lang.cms.form.title }}
                  <required_span/>
                </label>
                <input
                    :maxlength="maxlength.title"
                    type="text"
                    :placeholder="$lang.cms.form.title"
                    class="form-control"
                    v-model="cms.title"
                />
                <small
                    class="error"
                    v-if="$v.cms.title.$error && !$v.cms.title.required"
                >{{ $lang.cms.validation.required.title }}</small
                >
              </div>
              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.cms.description.$error }"
              >
                <template>
                  <!--                  <vue-editor-->
                  <!--                    v-model="cms.description"-->
                  <!--                    :editor-toolbar="customToolbar"-->
                  <!--                  />-->
                  <ckeditor v-model="cms.description"></ckeditor>
                </template>
                <small
                    class="error"
                    v-if="
                    $v.cms.description.$error && !$v.cms.description.required
                  "
                >{{ $lang.cms.validation.required.description }}</small
                >
              </div>
              <div
                  class="form-group "
                  :class="{ 'form-group--error': $v.cms.status.$error }"
              >
                <label class="form__label"
                >{{ $lang.cms.form.status }}
                  <required_span/>
                </label>
                <v-select :options="statusOptions" v-model="cms.status">
                </v-select>
                <small
                    class="error"
                    v-if="$v.cms.status.$error && !$v.cms.status.required"
                >{{ $lang.cms.validation.required.status }}</small
                >
              </div>
              <div v-if="cms.code === 'T&C'">
                <input
                    type="checkbox"
                    :value="1"
                    v-model="cms.terms"
                />&nbsp;&nbsp;
                <label class="form__label">Notify users</label>
                <br>
                <input
                    type="checkbox"
                    :value="1"
                    v-model="cms.terms_force"
                />&nbsp;&nbsp;
                <label class="form__label">Force update for users</label>
              </div>
              <div v-if="cms.code === 'P&P'">
                <input
                    type="checkbox"
                    :value="1"
                    v-model="cms.privacy"
                />&nbsp;&nbsp;
                <label class="form__label">Notify users</label>
                <br>
                <input
                    type="checkbox"
                    :value="1"
                    v-model="cms.privacy_force"
                />&nbsp;&nbsp;
                <label class="form__label">Force update for users</label>
              </div>

              <CRow>
                <CCol col="6" class="text-left">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      :disabled="submitted"
                  >
                    <CIcon name="cil-check-circle"/>
                    {{ $lang.buttons.general.crud.update }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {cms, cmsMaster} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import {Maxlength} from "../../store/maxlength";
import CKEditor from 'ckeditor4-vue';

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
Vue.use(CKEditor);
export default {
  name: "EditPage",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      submitted: false,
      customToolbar: [
        // ["bold", "italic", "underline"],
        // [{ list: "ordered" }, { list: "bullet" }],
        // ["image", "code-block"]
      ],
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      modules: [],
      statusOptions: [],
      cmsMasterPages: [],
      activePage: 1,
      module: cms,
      typeOptions: [
        {
          'id': 'Company',
          'label': 'Company',
        },
        {
          'id': 'Student',
          'label': 'Student',
        },
        {
          'id': 'College',
          'label': 'College',
        },
      ],
      cmsMasterModule: cmsMaster,
      cms: {
        code: "",
        page: "",
        title: "",
        description: "",
        status: "",
        terms: "",
        privacy: "",
        terms_force: "",
        type: "",
        privacy_force: ""
      },
      maxlength: {
        title: Maxlength.cms.title,
      },
    };
  },
  validations: {
    cms: {
      type: {
        required,
      },
      page: {
        required,
      },
      title: {
        required,
      },
      description: {
        required,
      },
      status: {
        required,
      },
    },
  },
  created() {
    store.commit("showLoader", false); // Loader Off
  },
  mounted() {
    let self = this;
    self.statusOptions.push(
        {value: "Active", label: "Active"},
        {value: "InActive", label: "InActive"}
    );
    const id = this.$route.params.id;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          if (response.data) {
            let responseData = response.data.data;
            self.cms.description = responseData.description;
            self.cms.title = responseData.title;
            self.cms.status = responseData.status;
            self.cms.page = {
              value: responseData.page_master.pageId,
              label: responseData.page_master.pageTitle,
            };
            self.cms.type = {
              id: responseData.type,
              label: responseData.type,
            };
            self.cms.code = responseData.page_master.code;
            axios.get(this.listUrlApi(this.cmsMasterModule)).then((response) => {
              response.data.data.map(function (value, key) {
                self.cmsMasterPages.push({value: value.id, label: value.title});
              });
            });

            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
    store.commit("showLoader", false); // Loader Off
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    onSubmit() {
      let self = this;
      this.$v.cms.$touch();
      if (this.$v.cms.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const postData = {
          page_master_id: self.cms.page.value,
          title: self.cms.title,
          description: self.cms.description,
          status: self.cms.status.value,
          privacy: self.cms.privacy,
          privacy_force: self.cms.privacy_force,
          terms: self.cms.terms,
          terms_force: self.cms.terms_force,
          type: self.cms.type.label,
        };
        axios
            .post(this.updateUrlweb(this.module, self.editId), postData)
            .then((response) => {
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem(
                    "notificationMessage",
                    response.data.message
                );
                self.dismissCountDown = 10;
                this.$router.push({name: "cms"});
              } else {
                self.err_msg = response.data.response.message;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              }
            }).catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
        });
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
